<form [formGroup]="billingform" (ngSubmit)="onSubmit()" class="login-form" class="login-form">
    <!-- <img [src]="file" alt=""> -->
    <div class="form-wrp">

        <div class="form-steps payment-setp step3">
            <div ngbAccordion class="accordion payment-info-wrp" id="paymentAccordion"
                #accordion="ngbAccordion">

                <div *ngIf="loader" style="border: 1px solid #c3deb9;border-radius: 35px;
                padding: 20px; margin-bottom: 10px;">
                    <!-- Skeleton Loader -->
                   
                    <ngx-skeleton-loader count="1" appearance="line" [theme]="{ width: '45%', height: '30px', marginBottom: '10px' ,
                        borderTopLeftRadius: '10px', 
                    borderTopRightRadius: '10px',
                    borderBottomLeftRadius: '10px',
                    borderBottomRightRadius: '10px',
                    marginLeft:'20px'
                  
                    }"></ngx-skeleton-loader>

                        <ngx-skeleton-loader count="1" appearance="line" [theme]="{ width: '45%', height: '30px', marginBottom: '10px' ,
                        borderTopLeftRadius: '10px', 
                        borderTopRightRadius: '10px',
                        borderBottomLeftRadius: '10px',
                        borderBottomRightRadius: '10px',
                        marginLeft:'20px'
                        }"></ngx-skeleton-loader>
                        <ngx-skeleton-loader count="1" appearance="line" [theme]="{ width: '45%', height: '30px', marginBottom: '10px' ,
                        borderTopLeftRadius: '10px', 
                    borderTopRightRadius: '10px',
                    borderBottomLeftRadius: '10px',
                    borderBottomRightRadius: '10px',
                    marginLeft:'20px'
                  
                    }"></ngx-skeleton-loader>

                        <ngx-skeleton-loader count="1" appearance="line" [theme]="{ width: '45%', height: '30px', marginBottom: '10px' ,
                        borderTopRightRadius: '10px',
                        borderBottomLeftRadius: '10px',
                        borderBottomRightRadius: '10px',
                        marginLeft:'20px'
                        }"></ngx-skeleton-loader>



                    <ngx-skeleton-loader count="1" appearance="line" [theme]="{ width: '45%', height: '30px', 
                    borderTopLeftRadius: '10px', 
                    borderTopRightRadius: '10px',
                    borderBottomLeftRadius: '10px',
                    borderBottomRightRadius: '10px',
                    marginLeft:'20px'
                    }"></ngx-skeleton-loader>
                    <ngx-skeleton-loader count="1" appearance="line" [theme]="{ width: '45%', height: '30px', 
                    borderTopLeftRadius: '10px', 
                borderTopRightRadius: '10px',
                borderBottomLeftRadius: '10px',
                borderBottomRightRadius: '10px',
                marginLeft:'20px'
              
                }"></ngx-skeleton-loader>

                   
                  </div>
                <div class="accordion-item" ngbAccordionItem="first" [collapsed]="false" *ngIf="!loader">
                    <div class="accordion-header title-wrp" ngbAccordionHeader>
                        <button class="accordion-button form-title" type="button" ngbAccordionButton>
                            <span class="ico">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <g id="frame" transform="translate(-108 -252)">
                                        <path id="Vector"
                                            d="M4.75,0a4.746,4.746,0,0,0-.12,9.49.807.807,0,0,1,.22,0h.07A4.746,4.746,0,0,0,4.75,0Z"
                                            transform="translate(115.25 254)" fill="currentcolor" />
                                        <path id="Vector-2" data-name="Vector"
                                            d="M12.12,1.395a9.929,9.929,0,0,0-10.15,0A3.947,3.947,0,0,0,0,4.625a3.914,3.914,0,0,0,1.96,3.21,9.239,9.239,0,0,0,5.08,1.41,9.239,9.239,0,0,0,5.08-1.41,3.945,3.945,0,0,0,1.96-3.23A3.937,3.937,0,0,0,12.12,1.395Z"
                                            transform="translate(112.96 264.755)" fill="currentcolor" />
                                        <path id="Vector-3" data-name="Vector" d="M0,0H24V24H0Z"
                                            transform="translate(132 276) rotate(180)" fill="none" opacity="0" />
                                    </g>
                                </svg>
                            </span>
                            <span class="txt">Billing Address</span>
                        </button>
                    </div>
                    <div id="collapseOne" class="accordion-collapse collapse show" ngbAccordionCollapse>
                        <div  class="accordion-body" ngbAccordionBody >
                            <div class="acc-body-container" >
                                <div class="row" >

                                    <div class="col-md-6">
                                        <div class="input-wrp">
                                            <label for="firstName" class="label">First Name <span
                                                    class="text-danger">*</span> </label>
                                            <input type="text" class="form-control" formControlName="firstName"
                                                placeholder="First Name">
                                            <!-- <div *ngIf="billingform.get('firstName')?.invalid && billingform.get('firstName')?.touched"
                                                [ngClass]="{'form-error': billingform.get('firstName')?.invalid && billingform.get('firstName')?.touched}">
                                                First Name is required
                                            </div> -->
                                            <app-form-error
                                            [control]="billingform.controls['firstName']"
                                            validatorName="required"
                                            class="err-msg"
                                          >
                                            First Name is required.
                                          </app-form-error>
                                          
                                          <app-form-error
                                            [control]="billingform.controls['firstName']"
                                            validatorName="lettersOnly"
                                            class="err-msg"
                                          >
                                            Only letters are allowed.
                                          </app-form-error>
                                           
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="input-wrp">
                                            <label for="lastName" class="label">Last Name <span
                                                    class="text-danger">*</span></label>
                                            <input type="text" id="lastName" class="form-control"
                                                formControlName="lastName" placeholder="Last Name">
                                            <!-- <div *ngIf="billingform.get('lastName')?.invalid && billingform.get('lastName')?.touched"
                                                [ngClass]="{'form-error': billingform.get('lastName')?.invalid && billingform.get('lastName')?.touched}">
                                                Last Name is required
                                            </div> -->

                                            <app-form-error
                                            [control]="billingform.controls['lastName']"
                                            validatorName="required"
                                            class="err-msg"
                                          >
                                            Last Name is required.
                                          </app-form-error>
                                          
                                          <app-form-error
                                            [control]="billingform.controls['lastName']"
                                            validatorName="lettersOnly"
                                            class="err-msg"
                                          >
                                            Only letters are allowed.
                                          </app-form-error>
                                        </div>
                                    </div>

                                    <div class="col-md-12">
                                        <div class="form-group input-wrp">
                                            <label for="phoneNumber" class="label">Phone Number <span
                                                    class="text-danger">*</span></label>
                                            <ngx-intl-tel-input class="form-control flag-input"
                                                [enableAutoCountrySelect]="true" [searchCountryFlag]="true"
                                                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name, SearchCountryField.DialCode]"
                                                [selectFirstCountry]="false" formControlName="phoneNumber"
                                                name="phoneNumber" [selectedCountryISO]="CountryISO.India"
                                                [enablePlaceholder]="true" customPlaceholder="Phone Number"
                                                [phoneValidation]="false" [separateDialCode]="true"
                                                [numberFormat]="PhoneNumberFormat.National"
                                                (input)="restrictSpecialCharacters($event)">
                                            </ngx-intl-tel-input>

                                            <ng-container>
                                                <app-form-error
                                            [control]="billingform.controls['phoneNumber']"
                                            validatorName="required"
                                            class="err-msg"
                                          >
                                          Phone number is required.
                                          
                                          </app-form-error>
                                          <app-form-error
                                          [control]="billingform.controls['phoneNumber']"
                                          validatorName="invalidLength"
                                          class="err-msg">
                                          Enter a valid phone number.
                                        </app-form-error>
                                            </ng-container>


                                        </div>
                                    </div>
                                  
                                    <!-- <div class="col-md-6">
                                        <div class="input-wrp">
                                            <label for="apartment" class="label">Apartment/Suit <span
                                                    class="text-danger">*</span></label>
                                            <input id="apartment" formControlName="apartment" type="text"
                                                class="form-control" placeholder="Enter Apartment/Suit">
                                                <app-form-error
                                                [control]="billingform.controls['apartment']"
                                                validatorName="pattern"
                                                class="err-msg">
                                                Please enter valid apartment details.
                                              </app-form-error>

                                              <app-form-error
                                                [control]="billingform.controls['apartment']"
                                                validatorName="required"
                                                class="err-msg">
                                                Apartment details is required.
                                              </app-form-error>
                                              <app-form-error
                                                [control]="billingform.controls['apartment']"
                                                validatorName="trimError"
                                                class="err-msg">
                                                The apartment details cannot start with a space or be just spaces.
                                              </app-form-error>

                                              
                                        </div>
                                    </div> -->

                                    <div class="col-md-12">
                                        <div class="input-wrp">
                                            <label for="address" class="label">Address <span
                                                    class="text-danger">*</span></label>
                                            <textarea id="address" formControlName="address" class="form-control"
                                                rows="3"></textarea>
                                                <app-form-error
                                                [control]="billingform.controls['address']"
                                                validatorName="required"
                                                class="err-msg">
                                                Address details is required.
                                              </app-form-error>
                                              <app-form-error
                                                [control]="billingform.controls['address']"
                                                validatorName="trimError"
                                                class="err-msg">
                                                The address details cannot start with a space or be just spaces.
                                              </app-form-error>
                                            
                                        </div>
                                    </div>

                                    <!-- <div class="col-md-6">
                                        <div class="input-wrp">
                                            <label for="country" class="label">Country <span
                                                    class="text-danger">*</span></label>
                                            <select formControlName="country" class="form-control" id="country">
                                                <option value="" disabled>Select</option>
                                                <option *ngFor="let country of country" [value]="country.iso2">{{
                                                    country.country }}</option>
                                            </select>
                                            <span
                                                *ngIf="billingform.get('country')?.touched && billingform.get('country')?.invalid"
                                                class="form-error">
                                                Please select your country
                                            </span>
                                        </div>
                                    </div> -->

                                    <div class="col-md-6">
                                        <div class="input-wrp">
                                            <label for="country" class="label">Country <span
                                                    class="text-danger">*</span></label>
                                            <div class="form-group angular-select week-select">
                                                <ng-select class="form-control" name="country" id="country"
                                                    #ngSelectComponentInterval class="angular-select" [items]="country"
                                                    bindLabel="country" [(ngModel)]="selectCountry"
                                                    placeholder="Select country" formControlName="country"
                                                    [clearable]="true" [editableSearchTerm]="true"
                                                    (change)="updateCountry($event)">
                                                </ng-select>
                                            </div>

                                            <app-form-error
                                                [control]="billingform.controls['country']"
                                                validatorName="required"
                                                class="err-msg">
                                                Please select a country.
                                              </app-form-error>
                                           
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="input-wrp">
                                            <label for="city" class="label">City <span class="text-danger">*</span>
                                            </label>
                                            <input id="city" formControlName="city" type="text" class="form-control"
                                                placeholder="City">

                                                <app-form-error
                                                [control]="billingform.controls['city']"
                                                validatorName="required"
                                                class="err-msg">
                                                Please enter the city.
                                              </app-form-error>
                                              <app-form-error
                                                [control]="billingform.controls['city']"
                                                validatorName="trimError"
                                                class="err-msg">
                                                Please enter a valid city.
                                              </app-form-error>
                                              <app-form-error
                                                [control]="billingform.controls['city']"
                                                validatorName="lettersOnly"
                                                class="err-msg">
                                                Please enter a valid city.
                                              </app-form-error>
                                           

                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="input-wrp">
                                            <label for="state" class="label">State <span
                                                    class="text-danger">*</span></label>
                                            <input id="state" formControlName="state" type="text" class="form-control"
                                                placeholder="State">

                                                <app-form-error
                                                [control]="billingform.controls['state']"
                                                validatorName="required"
                                                class="err-msg">
                                                Please enter the state.
                                              </app-form-error>
                                              <app-form-error
                                                [control]="billingform.controls['state']"
                                                validatorName="trimError"
                                                class="err-msg">
                                                Please enter a valid state.
                                              </app-form-error>
                                              <app-form-error
                                                [control]="billingform.controls['state']"
                                                validatorName="lettersOnly"
                                                class="err-msg">
                                                Please enter a valid state.
                                              </app-form-error>
                                            
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="input-wrp">
                                            <label for="postalCode" class="label">Postal Code <span
                                                    class="text-danger">*</span></label>
                                            <input id="postalCode" formControlName="postalCode" type="text"
                                                class="form-control" placeholder="Postal Code" pattern="\d*">
                                            <!-- Set maximum length to 6 digits, enforce numeric input only -->

                                            <!-- Error messages for validation -->
                                            <app-form-error
                                            [control]="billingform.controls['postalCode']"
                                            validatorName="required"
                                            class="err-msg">
                                            Postal Code is required.
                                          </app-form-error>
                                          <app-form-error
                                            [control]="billingform.controls['postalCode']"
                                            validatorName="maxlength"
                                            class="err-msg">
                                            Enter a valid postal code.
                                          </app-form-error>
                                          <app-form-error
                                            [control]="billingform.controls['postalCode']"
                                            validatorName="minlength"
                                            class="err-msg">
                                            Enter a valid postal code.
                                          </app-form-error>
                                          <app-form-error
                                          [control]="billingform.controls['postalCode']"
                                          validatorName="pattern"
                                          class="err-msg">
                                          Enter a valid postal code.
                                        </app-form-error>
                                        
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="accordion-item" ngbAccordionItem="second" #secondAccordion="ngbAccordionItem" [collapsed]="false">
                    <div class="accordion-header title-wrp" ngbAccordionHeader>
                        <button class="form-title accordion-button collapsed" type="button" ngbAccordionButton>
                            <span class="ico">
                                <svg width="24" height="24" viewBox="0 0 24 24">
                                    <g id="vuesax_bold_card-tick" data-name="vuesax/bold/card-tick"
                                        transform="translate(-300 -508)">
                                        <g id="card-tick">
                                            <path id="Vector"
                                                d="M0,1.21v5a4.135,4.135,0,0,0,4.14,4.14H15.85A4.153,4.153,0,0,0,20,6.2V1.21A1.208,1.208,0,0,0,18.79,0H1.21A1.208,1.208,0,0,0,0,1.21ZM6,7H4A.75.75,0,0,1,4,5.5H6A.75.75,0,0,1,6,7Zm6.5,0h-4a.75.75,0,0,1,0-1.5h4a.75.75,0,0,1,0,1.5Z"
                                                transform="translate(302 518.25)" fill="currentcolor" />
                                            <path id="Vector-2" data-name="Vector"
                                                d="M11.5,1.21V4.14a1.208,1.208,0,0,1-1.21,1.21H1.21A1.218,1.218,0,0,1,0,4.12,4.131,4.131,0,0,1,4.14,0h6.15A1.208,1.208,0,0,1,11.5,1.21Z"
                                                transform="translate(302 511.4)" fill="currentcolor" />
                                            <path id="Vector-3" data-name="Vector"
                                                d="M4.97,0H2.03A1.866,1.866,0,0,0,0,2.03V4.97A1.866,1.866,0,0,0,2.03,7H4.97A1.866,1.866,0,0,0,7,4.97V2.03A1.866,1.866,0,0,0,4.97,0Zm.66,2.45L3.26,5.22a.49.49,0,0,1-.37.18H2.87a.514.514,0,0,1-.37-.15L1.4,4.17a.519.519,0,0,1,0-.73.51.51,0,0,1,.73,0l.7.69,2-2.34a.521.521,0,0,1,.73-.06A.5.5,0,0,1,5.63,2.45Z"
                                                transform="translate(315 510)" fill="currentcolor" />
                                            <path id="Vector-4" data-name="Vector" d="M0,0H24V24H0Z"
                                                transform="translate(300 508)" fill="none" opacity="0" />
                                        </g>
                                    </g>
                                </svg>
                            </span>
                            <span class="txt">Payment Information</span>
                        </button>
                    </div>
                    <div class="collapsePayment accordion-collapse collapse" ngbAccordionCollapse>
                        <div class="accordion-body" ngbAccordionBody>
                            <div class="acc-body-container">
                                <div class="row">
                                    <!-- <div class="col-md-12">
                                        <div class="input-wrp">
                                            <label for="cardNumber" class="label">Card Detail</label>
                                            <input id="cardNumber" type="text" class="form-control"
                                                placeholder="Card Number">

                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="input-wrp">
                                            <label for="" class="label">MM/YY</label>
                                            <input type="text" class="form-control" placeholder="MM/YY">
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="input-wrp">
                                            <label for="" class="label">CVV</label>
                                            <input type="text" class="form-control" placeholder="CVV">
                                        </div>
                                    </div> -->
                                    <app-payment [clientSecret]="ClientSecret" [BillingDetails]="billingform.value"
                                        [customerId]="customerId" [setupIntend]="setUpIntentId"
                                        [priceId]="selectedPlanId" [Coupon]="coupon"></app-payment>
                                    <!-- <div class="sep-wrp">
                                        <h3 class="sep-title">Choose Payment Gateway</h3>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="input-wrp">
                                            <div class="payment-check form-control">
                                                <label for="paymentCheck" class="custom-chk">
                                                    <input type="radio" name="paymentCheck" id="paymentCheck1"
                                                        class="custom-chk-inp">
                                                    <span class="radio"></span>

                                                    <div class="paymentIco">
                                                        <img src="../../../../../assets/img/logo-paypal.svg"
                                                            alt="paypal">
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="input-wrp">
                                            <div class="payment-check form-control">
                                                <label for="paymentCheck" class="custom-chk">
                                                    <input type="radio" name="paymentCheck" id="paymentCheck2"
                                                        class="custom-chk-inp">
                                                    <span class="radio"></span>

                                                    <div class="paymentIco">
                                                        <img src="../../../../../assets/img/logo-stripe.svg"
                                                            alt="stripe">
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                            <div class="act-code">
                                <div class="act-code-wrp">
                                    <!-- <a  class="act-code-link" type="button" data-bs-toggle="modal"
                                    data-bs-target="#activationCode">
                                    <span class="ico">
                                        <svg width="24" height="24" viewBox="0 0 24 24">
                                            <g id="vuesax_bold_password-check" data-name="vuesax/bold/password-check"
                                                transform="translate(-620 -188)">
                                                <path id="Vector"
                                                    d="M3.75,2H1.5V.75A.755.755,0,0,0,.75,0,.755.755,0,0,0,0,.75v18.5A.755.755,0,0,0,.75,20a.755.755,0,0,0,.75-.75V18H3.75a4,4,0,0,0,4-4V6A4,4,0,0,0,3.75,2Z"
                                                    transform="translate(634.25 190)" fill="currentcolor" />
                                                <path id="Vector-2" data-name="Vector"
                                                    d="M4,0A4,4,0,0,0,0,4v8a4,4,0,0,0,4,4H9a1,1,0,0,0,1-1V1A1,1,0,0,0,9,0Zm.17,8.38a1.155,1.155,0,0,1-.21.33,1.155,1.155,0,0,1-.33.21.943.943,0,0,1-.76,0,1.155,1.155,0,0,1-.33-.21,1.461,1.461,0,0,1-.22-.33A.986.986,0,0,1,2.25,8a1.052,1.052,0,0,1,.29-.71l.15-.12a.757.757,0,0,1,.18-.09.636.636,0,0,1,.18-.06,1,1,0,0,1,.91.27A1.052,1.052,0,0,1,4.25,8,1,1,0,0,1,4.17,8.38Zm3.5,0a1.155,1.155,0,0,1-.21.33,1.155,1.155,0,0,1-.33.21.943.943,0,0,1-.76,0,1.155,1.155,0,0,1-.33-.21.99.99,0,0,1-.21-1.09,1.032,1.032,0,0,1,.21-.33A1,1,0,0,1,7.75,8,1,1,0,0,1,7.67,8.38Z"
                                                    transform="translate(622.5 192)" fill="currentcolor" />
                                                <path id="Vector-3" data-name="Vector" d="M0,0H24V24H0Z"
                                                    transform="translate(644 212) rotate(180)" fill="none"
                                                    opacity="0" />
                                            </g>
                                        </svg>
                                    </span>
                                    <span (click)="coupon === null ? enterActivationCode() : ''" class="txt">
                                        {{ coupon === null ? 'Enter Activation Code' : appliedCoupon }}  
                                    </span>
                                </a> -->
                                    <!-- <span class="remove-coup" [ngStyle]="{ display: coupon !== null ? 'inline' : 'none' }"   (click)="coupon !== null ? removeActivationCode() : ''">&times;</span> -->

                                    <span class="act-code-amt">{{(PlanAmount)| currency:'USD'}}</span>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
                <div *ngIf="coupon!=null" class="amounts">
                    <div class="amount-row">
                        <span class="amount-text">Subtotal</span>
                        <span class="amount-value">{{(PlanAmount)| currency:'USD'}}</span>
                    </div>
                    <div class="amount-row">
                        <span class="amount-text">Discount ({{discountPercentage}}%)</span>
                        <span class="amount-value">-{{(PlanAmount-(PlanAmount-discountAmount))| currency:'USD'}}</span>
                    </div>
                    <!-- <div class="amount-row">
                        <span class="amount-text">Tax (0%)</span>
                        <span class="amount-value">$0.00</span>
                      </div> -->
                    <div class="amount-row total">
                        <span class="amount-text">Total</span>
                        <span class="amount-value">{{(PlanAmount-discountAmount)| currency:'USD'}}</span>
                    </div>

                </div>
                <div class="input-wrp mt-3 agreement">
                    <label for="remember" class="custom-chk">
                        <input type="checkbox" name="remember" id="remember" class="custom-chk-inp"
                            formControlName="tos">
                        <span class="chk"></span>
                    </label>
                    <span>I agree to <a class="links links-primary" style="cursor: pointer;"
                        routerLink="/terms-and-condition" target="_blank">Terms & Conditions</a> and
                        <a style="cursor: pointer;" class="links links-primary" routerLink="/privacy-policy" target="_blank">Privacy
                            Policy</a></span>

                    <span
                        *ngIf="billingform.get('tos')?.invalid && (billingform.get('tos')?.dirty || billingform.get('tos')?.touched)"
                        class="form-error err-msg pt-1">
                        Please review and accept the Terms and Conditions to proceed.
                    </span>
                </div>
            </div>


            <div class="signup-action-footer">
                <a (click)="goToPreviousStep()" class="btn btn-primary actn-arrows prev">
                    <span class="ico">
                        <img src="../../../../assets/img/btn-arrow-prev.svg" alt="next">
                    </span>
                    <span class="txt">Back</span>

                </a>

                <!-- <a href="#" class="btn btn-primary actn-arrows next">
                    <span class="txt">Next</span>
                    <span class="ico">
                        <img src="../../../../assets/img/btn-arrow.svg" alt="next">
                    </span>
                </a> -->

                <button type="submit" class="btn btn-primary actn-arrows next paynow">
                    <span class="txt">
                        <span class="price">{{(PlanAmount-discountAmount)| currency:'USD'}}</span>
                        <span class="pay">Pay Now</span>
                    </span>
                    <span class="ico">
                        <img src="../../../../assets/img/btn-arrow.svg" alt="next">
                    </span>
                </button>
            </div>
        </div>
    </div>
</form>