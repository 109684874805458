<div class="email-verify">
    <div class="container custom-container">
        <div class="inner-sec">
            <div class="success-ico">
                <img src="../../../../assets/img/logo.svg" alt="tick">
            </div>
        <div class="verifiction">Email Already Verified!</div>
        <div class="text">Your email address is already verified. No further action is needed.</div>
        <!-- <div class="text bold">Thank you!</div>   -->
        <div class="two-btn-sec">
            <div class="home-btn">
                <a href="/home"  class="login-btn">Home</a>
            </div>
            <div class="home-btn">
                <a href="/signin" class="login-btn scnd-btn">
                    Login
                </a>
            </div>
         </div>
    </div>
          
    </div>
   </div>

