<div class="login-wrapper login-page">
    <div class="container">
        <div class="row login-row">
            <div class="col-lg-6 txt-col">
                <div class="title-ico top-ico">
                    <img src="../../../../assets/img/title-top-icons.png" alt="pages">
                </div>
                <div class="sec-title">
                    <span class="ico">
                        <img src="../../../../assets/img/logo-icon-white.svg" alt="ico">
                    </span>
                    <span class="txt">Sign up your account</span>
                </div>

                <h1 class="title">Real time SEO Montoring & Reporting Platform</h1>

                <p class="subtitle">
                  Creating a website for your company in less than a minute.
                </p>
                <div class="title-ico">
                    <img src="../../../../assets/img/title-bottom-icons.png" alt="pages">
                </div>
            </div>
            <div class="col-lg-6 form-col login-form-col">
                <a href="#" class="login-logo">
                    <img src="../../../../assets/../../../../assets/img/logo.svg" alt="logo">
                </a>
                <form action="#" class="login-form login" [formGroup]="userLoginForm" (ngSubmit)="onSubmit()">
                    <div class="form-wrp">

                        <div class="form-steps step-0">
                            <div class="input-wrp">
                                <label for="email" class="label">User Name Or E-mail ID</label>
                                <input id="email" type="text" class="form-control" placeholder="Enter E-mail ID"
                                    formControlName="email">
                                <div *ngIf="f['email'].touched && f['email'].invalid" class="error" [ngClass]="{
                                    'form-error': f['email'].errors && f['email'].touched
                                  }">
                                    <span *ngIf="f['email'].errors?.['required']" class="err-msg">E-mail is required.</span>
                                    <span *ngIf="f['email'].errors?.['email'] || f['email'].errors?.['commonEmail']" class="err-msg">Please enter a valid E-mail address.</span>
                                </div>
                            </div>


                            <div class="input-wrp">
                                <!-- <button class="btn btn-primary w-100">Submit</button> -->
                                <button class="btn btn-primary w-100" type="submit" [disabled]="loading">
                                  <span *ngIf="loading" class="spinner-border" role="status" aria-hidden="true"></span>
                                  <span *ngIf="!loading">Submit</span>
                              </button>
                            </div>

                            <div class="input-wrp">
                                <p class="signup-txt"><a routerLink="/signin" class="links links-primary">Back to Login</a></p>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="bg-wrap"></div>
</div>
