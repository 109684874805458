<app-shared-header [showButton1]="true" [showButton2]="false" ></app-shared-header>
<div class="login-wrapper signup-page">
    <div class="container">
        <div class="row login-row">
            <div class="col-lg-6 txt-col">
                <div class="title-ico top-ico">
                    <img src="../../../../assets/img/title-top-icons.png" alt="pages">
                </div>
                <div class="sec-title">
                    <span class="ico">
                        <img src="../../../../assets/img/logo-icon-white.svg" alt="ico" >
                    </span>
                    <span class="txt">
                        Sign up your account
                    </span>
                </div>

                <h1 class="title">Real time SEO Monitoring & Reporting Platform</h1>

                <p class="subtitle">
                    Creating a website for your company in less than a minute.
                </p>
                <div class="title-ico">
                    <img src="../../../../assets/img/title-bottom-icons.png" alt="pages">
                </div>
            </div>
            <div class="col-lg-6 form-col">
                <div class="step-wrp">
                    <ul class="steps">
                        <li class="step-itm"
                            [ngClass]="{'active': isActive('personal-info'), 'completed': isCompleted('personal-info')}">
                            <span class="number">
                                <span class="num">01</span>
                                <span class="chk-img"><img src="../../../../assets/img/check-green.svg"
                                        alt="check green"></span>
                            </span>
                            <span class="title">Company Info & Branding</span>
                        </li>

                        <li class="step-itm"
                            [ngClass]="{'active': isActive('subscription-plan'), 'completed': isCompleted('subscription-plan')}">
                            <span class="number">
                                <span class="num">02</span>
                                <span class="chk-img"><img src="../../../../assets/img/check-green.svg"
                                        alt="check green"></span>
                            </span>
                            <span class="title">Choose Plan</span>
                        </li>

                        <li class="step-itm"
                            [ngClass]="{'active': isActive('billing-and-payment'), 'completed': isCompleted('billing-and-payment')}">
                            <span class="number">
                                <span class="num">03</span>
                                <span class="chk-img"><img src="../../../../assets/img/check-green.svg"
                                        alt="check green"></span>
                            </span>
                            <span class="title">Billing Address & Payment Info</span>
                        </li>
                    </ul>
                </div>


                <!-- component -->
                <!-- <router-outlet></router-outlet> -->
                <div [ngStyle]="{'display': currentStep === 'personal-info' ? 'block' : 'none'}">
                    <app-signup-personal-info></app-signup-personal-info>
                </div>

                <div [ngStyle]="{'display': currentStep === 'subscription-plan' ? 'block' : 'none'}">
                    <app-signup-subscription-plan></app-signup-subscription-plan>
                </div>

                <div [ngStyle]="{'display': currentStep === 'billing-and-payment' ? 'block' : 'none'}">
                    <app-signup-billing-and-payment  [Showmodal]="showmodal"></app-signup-billing-and-payment>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-wrap"></div>
</div>