import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../service/auth-service';
import { ToastrService } from 'ngx-toastr';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { StepService } from '../../../../shared/step.service';
import { FormFieldService } from '../../../../shared/form-field.service';
import { CommonValidator } from '../../../../shared/validators/common.validator';



// Custom Validator Function for Minimum Length
export function minLengthValidator(minLength: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null; // No error if the value is empty
    }
    const value = control.value.replace(/\D/g, ''); // Remove non-digit characters
    return value.length < minLength ? { minLength: { requiredLength: minLength, actualLength: value.length } } : null;
  };
}
@Component({
  selector: 'app-signup-personal-info',
  templateUrl: './signup-personal-info.component.html',
  styleUrl: './signup-personal-info.component.scss'
})



export class SignupPersonalInfoComponent implements OnInit {

  loading: boolean = false;
  showPassword: boolean = false;
  showConfirmPassword: boolean = false;
  imgUpProgress:boolean = false;
  emailExists: boolean = false;
  emailChanged: boolean = false;

  personalInfoForm!: FormGroup;
  file: File | null = null;
  dropImage: any | null = null;
  isAddimgRemove: boolean = false;

  imageData: any = {
    name: '',
    url: '',
    component: 'client'
  };

  SearchCountryField = SearchCountryField;
  PhoneNumberFormat = PhoneNumberFormat;
  CountryISO = CountryISO;
  countryISO = CountryISO;
  selectedCountryISO =  CountryISO
  selectedDialCodeDtls: any = {
    name: 'US',
    code: ''
  }

  primaryColors: string[] = ['#C8B88A', '#E39D8C', '#E0B156', '#75CE9F', '#9EC278', '#B6BAD5', '#DF9A56', '#DD8E8B', '#83C7CF', '#D1B9BC', '#C4C3C3'];
  secondaryColors: string[] = ['#FFC082', '#C7DAC0', '#9B8AB7', '#FF5EBA', '#A1A1A1', '#113BAE', '#FFC300', '#DAF7A6', '#581845', '#C70039', '#900C3F'];

  constructor(
    private fb: FormBuilder,
    private router: Router, private route: ActivatedRoute,
    private authService: AuthService,
    private toasterService: ToastrService,
    private stepService: StepService,
    private formfieldService: FormFieldService
  ) {

  }


  ngOnInit(): void {
    // let img:any = localStorage.getItem('logo');
    // let img1 :File = JSON.parse(img)
    // const fileContent = img1;

    // // Convert content to a Blob
    // const blob = new Blob([fileContent], { type: img1.type });

    // // Create a File object using the Blob and file metadata
    // const files = new File([blob], img1.type, {
    //   // name:img1.name,
    //   // size: img1.size,
    //   type: img1.type,
    //   lastModified: img1.lastModified
    // }); 
    // this.imageData = files
    // console.log(this.imageData,img1)
    this.personalInfoForm = this.fb.group({
      firstName: ['', [Validators.required, this.lettersOnlyValidator.bind(this)]],
      lastName: ['', [Validators.required, this.lettersOnlyValidator.bind(this)]],
      email: ['', [
        Validators.required,
        Validators.email,
        // Validators.pattern(/^\w+([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
        CommonValidator.email,
      ]],
      phoneNumber: [null],
      primaryColor: ['#75CE9F'],
      secondaryColor: ['#CACACA'],
      password: ['', [Validators.required,Validators.maxLength(50), this.passwordValidator.bind(this)]],
      confirmPassword: ['', [Validators.required, this.passwordMatchValidator.bind(this)]],
      logo: ['']
    });


    this.personalInfoForm.get('password')?.valueChanges.subscribe(() => {
      // Trigger re-validation of confirm password
      this.personalInfoForm.get('confirmPassword')?.updateValueAndValidity();
    });

    this.personalInfoForm.get('email')?.valueChanges.subscribe(() => {
      this.emailChanged = true;
    });
    const storedData = sessionStorage.getItem('personalInfoFormData');
        if (storedData) {
          const personalData = JSON.parse(storedData);
          this.prefillFormWithStoredData(personalData);
        }     
  }


  prefillFormWithStoredData(formData:any) {
    console.log(formData)
    const cleanedPhoneNumber = this.cleanPhoneNumber(formData?.phoneNumber);
      // Use patchValue to update only the controls that match the stored data
      this.personalInfoForm.patchValue({
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        phoneNumber:{ number: cleanedPhoneNumber, dialCode: formData?.phoneNumber.dialCode, countryCode: formData?.phoneNumber.countryCode },
        primaryColor: formData.primaryColor,
        secondaryColor: formData.secondaryColor,
        // Note: Password and confirm password are usually not stored for security reasons
        // logo: formData.logo
      });
    }
   
    restrictSpecialCharacters(event: any) {
      if (event.target.id === 'country-search-box') {
        // This is the country search box, no replacement needed
        return;
      }
     
      const input = event.target;
      const cleanValue = input.value.replace(/[^0-9+]/g, ''); // Allows only numbers, spaces, and the plus sign
      input.value = cleanValue;

      const minLength = 4;
      const maxLength = 20;
      const phoneNumberControl = this.personalInfoForm.get('phoneNumber');
      
      // Extract and clean the phone number value
      let phoneNumber = phoneNumberControl?.value?.number || '';
      phoneNumber = phoneNumber.replace(/\D/g, ''); // Remove non-numeric characters
    
      // Check for required and length validation
      if (phoneNumber.length === 0) {
        phoneNumberControl?.setErrors({ required: true });
      } else if (phoneNumber.length < minLength || phoneNumber.length > maxLength) {
        phoneNumberControl?.setErrors({ invalidLength: true });
      } else {
        phoneNumberControl?.setErrors(null); // Clear any existing errors if valid
      }
      // this.personalInfoForm.get('phoneNumber')?.setValue(cleanValue); // Update form control value
    }
    
    
  lettersOnlyValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value?.toString().trim();

    // Check if the value starts with a space or contains non-letter characters
    const startsWithSpace = control.value?.toString().startsWith(' ');
    const hasOnlyLetters = /^[a-zA-Z\s]*$/.test(value);

    if (startsWithSpace || !hasOnlyLetters || value.length === 0) {
      return { lettersOnly: true };
    }

    return null;
  }

  getFieldError(controlName: string, error: string): boolean {
    return this.personalInfoForm.get(controlName)?.hasError(error) || false;
  }



  togglePasswordVisibility(fieldId: string) {
    const field = document.getElementById(fieldId) as HTMLInputElement;
    if (field) {
      const fieldType = field.type === 'password' ? 'text' : 'password';
      field.type = fieldType;

      if (fieldId === 'password') {
        this.showPassword = fieldType === 'text';
      } else if (fieldId === 'confirmPassword') {
        this.showConfirmPassword = fieldType === 'text';
      }
    }
  }


  passwordMatchValidator(control: AbstractControl): ValidationErrors | null {
    const password = this.personalInfoForm?.get('password')?.value;
    const confirmPassword = control.value;
    if (confirmPassword && confirmPassword !== password) {
      return { passwordMismatch: true };
    }
    return null;
  }


  passwordValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value?.toString();

    // Check for leading or trailing spaces
    const hasLeadingOrTrailingSpaces = value !== value.trim();

    // Check password criteria
    const hasUpperCase = /[A-Z]/.test(value);
    const hasLowerCase = /[a-z]/.test(value);
    const hasNumber = /[0-9]/.test(value);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(value);
    const isValidLength = value.length >= 8;

    // Combine all checks
    const valid = !hasLeadingOrTrailingSpaces && hasUpperCase && hasLowerCase && hasNumber && hasSpecialChar && isValidLength;

    return valid ? null : { passwordStrength: true };
  }

  goToNextStep() {
    const minLength = 4;
    const maxLength = 20;
    const phoneNumberControl = this.personalInfoForm.get('phoneNumber');
    
    // Extract and clean the phone number value
    let phoneNumber = phoneNumberControl?.value?.number || '';
    phoneNumber = phoneNumber.replace(/\D/g, ''); // Remove non-numeric characters
  
    // Check for required and length validation
    if (phoneNumber.length === 0) {
      phoneNumberControl?.setErrors({ required: true });
    } else if (phoneNumber.length < minLength || phoneNumber.length > maxLength) {
      phoneNumberControl?.setErrors({ invalidLength: true });
    } else {
      phoneNumberControl?.setErrors(null); // Clear any existing errors if valid
    }
    
    console.log('s', this.personalInfoForm.value);
    if (this.personalInfoForm.invalid) {
      this.personalInfoForm.markAllAsTouched();
      this.loading = false;
      return;
    }

    this.checkEmailAddress();

    if (this.personalInfoForm.valid && !this.emailExists) {
      this.formfieldService.setPersonalInfoFormData(this.personalInfoForm);
      this.stepService.setStep('subscription-plan');
      sessionStorage.setItem('currentStep','subscription-plan')

    } else {
      const emailControl = this.personalInfoForm.get('email');
      emailControl?.setErrors({ emailExists: true });
      emailControl?.markAsTouched();
    }
   

  }

  onColorChange(controlName: string): void {
    const colorValue = this.personalInfoForm.get(controlName)?.value;
    this.personalInfoForm.get(controlName)?.setValue(colorValue, { emitEvent: false });
  }

  onColorSelect(controlName: string, colorValue: string): void {
    this.personalInfoForm.get(controlName)?.setValue(colorValue);
  }


  handleUploadSuccess(event: any): void {

    this.dropImage = event;
    this.formfieldService.setDropImageData(this.dropImage);
    this.personalInfoForm.get('logo')?.setValue(this.dropImage.dropImage); // Set the logo field's value
    this.personalInfoForm.get('logo')?.updateValueAndValidity();
    console.log('Upload successful event received in AppComponent:', this.dropImage);
    this.imgUpProgress = false;
    // Handle the uploaded image here
  }


  

  handleRemoveImg(event: any) {
    if (!Array.isArray(this.dropImage)) {
      this.dropImage = [];
    }

    this.dropImage = this.dropImage.filter((image: any) => image.name !== event.name);

    if (this.dropImage.length === 0) {
      this.personalInfoForm.get('logo')?.setValue(null);
    }
    this.personalInfoForm.get('logo')?.updateValueAndValidity();
    console.log('Updated dropImage array:', this.dropImage);
    this.imgUpProgress = false;
  }
  uploadProgress(event:any){
  this.imgUpProgress = true;
  }
  checkEmailAddress(): void {

    const emailControl = this.personalInfoForm.get('email');
    const email = emailControl?.value;

    if (!this.emailChanged || emailControl?.invalid) {
      return;
    }

    this.loading = true;
    if (email) {
      // Create the request body
      const requestBody = { email: email };

      // Call the API to check if the email exists
      this.authService.verifyEmail(requestBody).subscribe(
        (res: any) => {
          if (res) {
            // Handle the response as needed
            this.emailExists = false;
            this.loading = false;
          }
        }, (err: any) => {
          this.emailExists = true;
          emailControl?.setErrors({ emailExists: true });
          emailControl?.markAsTouched();
          this.loading = false;

        });
    } else {
      this.loading = false;
    }
  }

  cleanPhoneNumber(phoneData: any): string {
    if (phoneData && phoneData.number) {
      let phone = phoneData.number || '';
      let cleanPhoneNumber = phone.replace(/\D/g, ''); // Remove non-digit characters

      if (cleanPhoneNumber.startsWith('0')) {
        cleanPhoneNumber = cleanPhoneNumber.slice(1); // Remove leading zero
      }

      return cleanPhoneNumber;
    }
    return '';
  }

  disableCopyPaste(event: ClipboardEvent) {
    event.preventDefault();
    this.toasterService.warning("Copy-pasting is disabled.","Warning!",{
      progressBar: true,
      closeButton: true,
      timeOut: 3000,
    })
  }
}
