<section class="privacy-banner">
    <div class="banner-title">
        We value your privacy and trust.
    </div>
</section>
<section class="section-terms-privacy">
    <div class="container  custom-container">
        <div class="row">
            <div class="col">
                <h4>Our Commitment to Privacy</h4>
                <p>Dover Consultants LLC (“we,” “our,” or “us”) is committed to protecting the privacy and security of your personal information. This Privacy Policy outlines the types of information we collect from you, how we use and protect that information, and your rights regarding your personal data. By using our website and services, you consent to the practices described in this policy.</p>

                <h4>Information We Collect</h4>

                <p>When you visit our website or use our services, we may collect certain types of information:</p>

                <h4>Personal Information:</h4>
                <p>We may collect personal information, such as your name, email address, phone number, and other contact details, only when you voluntarily provide it to us through forms or other means on our website.</p>

                <h4>How We Use Your Information</h4>
                <p>We use the information we collect for the following purposes:<br>
                    <strong>To facilitate Services transactions</strong>:The information we collect is used to complete the Service process.<br>
                    <strong>improve our services</strong>:We may use the information to enhance and customise our services, website content, and userexperience.<br>
                    <strong>communicate with you</strong>:We may use your contact information to respond to your inquiries, provide updates on Our Services.</p>

                <h4>Opt-In for Marketing Text Messages:</h4>
                <p>By submitting the contact form and signing up for texts, you consent to receive marketing text messages from Dover Consultants LLC at the number provided. Consent is not a condition of purchase. Message and data rates may apply. You can unsubscribe at any time by replying STOP to the received SMS (texts) or clicking the unsubscribe link (where available) in the marketing text messages.</p>   
                
                <h4>Opt-In Information and Third-Party Sharing</h4>
                <p>We respect your privacy choices, especially concerning the sharing of your personal information. Any information you provide through opt-in consent will not be shared with third parties for marketing or promotional purposes.</p>

                <h4>Data Security</h4>
                <p>We take reasonable measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the Internet or electronic storage is entirely secure, and we cannot guarantee absolute security.</p>

                <h4>Your Rights</h4>
                <p>You have the right to access, update, correct, and delete your personal information we hold. If you wish to exercise any of these rights, please contact us at

                    <a class="links" href="mailto:info@morebacklinks.net">info&#64;morebacklinks.net</a></p>

                <h4>Changes to this Privacy Policy</h4>
                <p>We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. The updated policy will be posted on our website, and the effective date will be revised accordingly.</p>

                <h4>Contact Us</h4>
                <p>If you have any questions, concerns, or requests related to this Privacy Policy, please contact us at <a class="links" href="mailto:info@morebacklinks.net">info&#64;morebacklinks.net</a></p>
            </div>
        </div>
    </div>
</section>