import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../service/auth-service';
import { ProfileService } from '../../service/profile-service';
import { ToastrService } from 'ngx-toastr';
import { MustMatch } from '../../../shared/validators/common.validator';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.scss'
})
export class ChangePasswordComponent implements OnInit{
  public userLoginForm!: UntypedFormGroup;
  loading:boolean = false;
  public passwordFieldType: string = 'password';
  public confirmPasswordFieldType: string = 'password';

  constructor(
    private router:Router,
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private profileService: ProfileService,
  ){}


  ngOnInit(): void {
		this.userLoginForm = this.formBuilder.group({
      token : [''],
			password: ['', [Validators.required, this.passwordValidator.bind(this),Validators.maxLength(50)]],
      confirmPassword: ['', [Validators.required, this.passwordMatchValidator.bind(this)]],
    },
    {
      validators: MustMatch('password', 'confirmPassword'),
    }
  );
  const token =this.route.snapshot.paramMap.get('token');
  this.userLoginForm.patchValue({ token });
      if (token) {
        this.profileService.verifyToken(token).subscribe(res => {
          if(res.success){
            console.log('success')
          }
        },(err:any)=>{
          this.router.navigate(['/reset-password-failed']);
        });
      }
      
    

	}
  get f() {
		return this.userLoginForm.controls;
	}

  passwordValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    const hasUpperCase = /[A-Z]/.test(value);
    const hasLowerCase = /[a-z]/.test(value);
    const hasNumber = /[0-9]/.test(value);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(value);
    const isValidLength = value.length >= 8;

    const valid = hasUpperCase && hasLowerCase && hasNumber && hasSpecialChar && isValidLength;
    return valid ? null : { passwordStrength: true };
  }

  passwordMatchValidator(control: AbstractControl): ValidationErrors | null {
    const password = this.userLoginForm?.get('password')?.value;
    const confirmPassword = control.value;
    if (confirmPassword && confirmPassword !== password) {
      return { passwordMismatch: true };
    }
    return null;
  }

  disableCopyPaste(event: ClipboardEvent) {
    event.preventDefault();
    this.toastrService.warning("Copy-pasting is disabled.","Warning!",{
      progressBar: true,
      closeButton: true,
      timeOut: 3000,
    })
  }

  onSubmit(){

    console.log("resettt",this.userLoginForm.invalid)
    console.log("resettt1",this.userLoginForm.value)
    if (this.userLoginForm.invalid) {
      this.userLoginForm.markAllAsTouched();
      return;
    }
    this.loading = true;
    this.authService.resetPassword(this.userLoginForm.value).subscribe(res => {
      if(res.success){
        this.loading = false;
        this.toastrService.success(res.message, "Success", {
          progressBar: true,
          // toastClass: "toast ngx-toastr",
          closeButton: true,
          timeOut: 3000,
          });
          this.router.navigate(['/signin']);
      }
    },(err:any)=>{
      this.loading = false;
      this.toastrService.error(err, "Error!", {
        progressBar: true,
        // toastClass: "toast ngx-toastr",
        closeButton: true,
        timeOut: 3000,
        });
    });
  }

  togglePasswordVisibility(field: string): void {
    if (field === 'password') {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    } else if (field === 'confirmPassword') {
      this.confirmPasswordFieldType = this.confirmPasswordFieldType === 'password' ? 'text' : 'password';
    }
  }
}
