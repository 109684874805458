<div class="login-wrapper login-page">
    <div class="container">
        <div class="row login-row">
            <div class="col-lg-6 txt-col">
                <div class="title-ico top-ico">
                    <img src="../../../../assets/img/title-top-icons.png" alt="pages">
                </div>
                <div class="sec-title">
                    <span class="ico">
                        <img src="../../../../assets/img/logo-icon-white.svg" alt="ico">
                    </span>
                    <span class="txt">Sign up your account</span>
                </div>

                <h1 class="title">Real time SEO Montoring & Reporting Platform</h1>

                <p class="subtitle">
                  Creating a website for your company in less than a minute.
                </p>
                <div class="title-ico">
                    <img src="../../../../assets/img/title-bottom-icons.png" alt="pages">
                </div>
            </div>
            <div class="col-lg-6 form-col login-form-col">
                <a href="#" class="login-logo">
                    <img src="../../../../assets/img/logo.svg" alt="logo">
                </a>
                <form action="#" class="login-form login" [formGroup]="userLoginForm" >
                    <div class="form-wrp">

                        <div class="form-steps step-0">
                            <div class="input-wrp">
                                <label for="" class="label">New password</label>
                                <div class="password-field-wrp">
                                    <button class="show-hide" (click)="togglePasswordVisibility('password')">
                                        <img src="../../../../assets/img/icon-hide-password.svg" alt="" class="hide">
                                        <img src="../../../../assets/img/icon-show-password.svg" alt="" class="show">
                                    </button>
                                    <input [type]="passwordFieldType" class="form-control"  formControlName="password" (paste)="disableCopyPaste($event)">
                                    <div *ngIf="f['password'].touched && f['password'].invalid" class="form-error">
                                        <small *ngIf="f['password'].errors?.['required']" class="err-msg">Password is required.</small>
                                        <small *ngIf="f['password'].errors?.['maxlength']" class="err-msg">Password cannot exceed 50 characters.</small>
                                            <!-- <small *ngIf="f['password'].errors?.['minLength']" class="err-msg">  Password must be at least 8 characters long.</small> -->
                                        <!-- <small *ngIf="f['password'].errors?.['pattern']" class="err-msg">Password must contain at least one
                                            number, one uppercase letter, and be at least 6 characters long.</small> -->
                                        <span class="err-msg"
                                            *ngIf="!f['password'].errors?.['required'] && f['password'].errors?.['passwordStrength']">
                                            Password must be minimum 8 characters with uppercase, lowercase, number, and special
                                            character.
                                        </span>
                                    </div>
                                </div>

                            </div>


                            <div class="input-wrp">
                                <label for="" class="label">Confirm password</label>
                                <div class="password-field-wrp">
                                    <button class="show-hide" (click)="togglePasswordVisibility('confirmPassword')">
                                        <img src="../../../../assets/img/icon-hide-password.svg" alt="" class="hide">
                                        <img src="../../../../assets/img/icon-show-password.svg" alt="" class="show">
                                    </button>
                                    <input [type]="confirmPasswordFieldType" class="form-control" formControlName="confirmPassword" (paste)="disableCopyPaste($event)">
                                    <div *ngIf="f['confirmPassword'].touched && f['confirmPassword'].invalid" class="form-error">
                                        <small *ngIf="f['confirmPassword'].errors?.['required']" class="err-msg">Confirm Password is required.</small>
                                        <!-- <small *ngIf="f['confirmPassword'].errors?.['mustMatch']" class="err-msg">
                                            Passwords must match the new password.
                                          </small> -->
                                          <div class="err-msg"
                                *ngIf="!userLoginForm.get('confirmPassword')?.hasError('required') && userLoginForm.get('confirmPassword')?.hasError('passwordMismatch')">
                                Passwords do not match
                            </div>
                                    </div>
                                </div>

                            </div>



                            <div class="input-wrp">
                                <button class="btn btn-primary w-100" (click)="onSubmit()">Submit</button>
                            </div>

                            <div class="input-wrp">
                                <p class="signup-txt"><a routerLink="/signin" class="links links-primary">Back to Login</a></p>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="bg-wrap"></div>
</div>
