<form action="#" [formGroup]="personalInfoForm" (ngSubmit)="goToNextStep()" class="login-form" autocomplete="off">
    <div class="form-wrp">
        <div class="form-steps step-1">
            <div class="title-wrp">
                <h3 class="form-title">
                    <span class="ico">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <g id="frame" transform="translate(-108 -252)">
                                <path id="Vector"
                                    d="M4.75,0a4.746,4.746,0,0,0-.12,9.49.807.807,0,0,1,.22,0h.07A4.746,4.746,0,0,0,4.75,0Z"
                                    transform="translate(115.25 254)" fill="currentcolor" />
                                <path id="Vector-2" data-name="Vector"
                                    d="M12.12,1.395a9.929,9.929,0,0,0-10.15,0A3.947,3.947,0,0,0,0,4.625a3.914,3.914,0,0,0,1.96,3.21,9.239,9.239,0,0,0,5.08,1.41,9.239,9.239,0,0,0,5.08-1.41,3.945,3.945,0,0,0,1.96-3.23A3.937,3.937,0,0,0,12.12,1.395Z"
                                    transform="translate(112.96 264.755)" fill="currentcolor" />
                                <path id="Vector-3" data-name="Vector" d="M0,0H24V24H0Z"
                                    transform="translate(132 276) rotate(180)" fill="none" opacity="0" />
                            </g>
                        </svg>
                    </span>
                    <span class="txt">Company Info & Branding</span>
                </h3>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="input-wrp">
                        <label for="firstName" class="label">First Name <span class="text-danger">*</span> </label>
                        <input type="text" id="firstName" class="form-control" formControlName="firstName"
                            placeholder="First Name">
                        <div *ngIf="personalInfoForm.get('firstName')?.invalid && personalInfoForm.get('firstName')?.touched"
                            [ngClass]="{'form-error': personalInfoForm.get('firstName')?.invalid && personalInfoForm.get('firstName')?.touched}">
                            <span class="err-msg" *ngIf="personalInfoForm.get('firstName')?.errors?.['required']">First
                                Name is required
                            </span>
                            <span class="err-msg"
                                *ngIf="!personalInfoForm.get('firstName')?.errors?.['required'] && personalInfoForm.get('firstName')?.errors?.['lettersOnly']">
                                Only letters are
                                allowed</span>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="input-wrp">
                        <label for="lastName" class="label">Last Name <span class="text-danger">*</span> </label>
                        <input type="text" id="lastName" class="form-control" formControlName="lastName"
                            placeholder="Last Name">
                        <div *ngIf="personalInfoForm.get('lastName')?.invalid && personalInfoForm.get('lastName')?.touched"
                            [ngClass]="{'form-error': personalInfoForm.get('lastName')?.invalid && personalInfoForm.get('lastName')?.touched}">
                            <span class="err-msg" *ngIf="personalInfoForm.get('lastName')?.errors?.['required']">Last
                                Name is required
                            </span>
                            <span class="err-msg"
                                *ngIf="!personalInfoForm.get('lastName')?.errors?.['required'] && personalInfoForm.get('lastName')?.errors?.['lettersOnly']">
                                Only letters are
                                allowed</span>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="input-wrp">
                        <label for="email" class="label">E-mail ID <span class="text-danger">*</span> </label>
                        <input type="email" id="email" class="form-control" formControlName="email" autocomplete="off"
                            placeholder="E-mail ID" (blur)="checkEmailAddress()">
                        <div *ngIf="personalInfoForm.get('email')?.touched && personalInfoForm.get('email')?.invalid"
                            class="form-error">
                            <span class="err-msg" *ngIf="personalInfoForm.get('email')?.hasError('required')">
                                E-mail is required
                            </span>
                            <span class="err-msg" *ngIf="personalInfoForm.get('email')?.hasError('email') || personalInfoForm.get('email')?.hasError('commonEmail')">
                                Valid E-mail format is required
                            </span>
                            <span class="err-msg" *ngIf="personalInfoForm.get('email')?.hasError('emailExists')">
                                Email already exists
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group input-wrp">
                        <label for="phoneNumber" class="label">Phone Number <span class="text-danger">*</span> </label>
                        <ngx-intl-tel-input class="form-control flag-input" [enableAutoCountrySelect]="true" 
                            [searchCountryFlag]="true"
                            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name, SearchCountryField.DialCode]"
                            [selectFirstCountry]="false" formControlName="phoneNumber" name="phoneNumber"
                            [selectedCountryISO]="selectedDialCodeDtls.name" [enablePlaceholder]="true"
                            customPlaceholder="Phone Number" [phoneValidation]="false" [separateDialCode]="true"
                            [numberFormat]="PhoneNumberFormat.National"
                            (input)="restrictSpecialCharacters($event)"                           >
                        </ngx-intl-tel-input>

                        <ng-container *ngIf="personalInfoForm.get('phoneNumber')?.touched">
                            <span class="form-error pt-1 err-msg" *ngIf="getFieldError('phoneNumber', 'required')">Phone
                                number
                                is required</span>
                            <span class="form-error pt-1 err-msg" *ngIf="personalInfoForm.get('phoneNumber')?.errors?.['invalidLength'] ">Enter a valid number.</span>

                        </ng-container>

                        <!-- <div *ngIf="personalInfoForm.get('phoneNumber')?.touched">
                            <div class="form-error" *ngIf="personalInfoForm.get('phoneNumber')?.hasError('required')">
                                Phone Number is required
                            </div>
                            <div class="form-error"
                                *ngIf="!personalInfoForm.get('phoneNumber')?.hasError('required') && personalInfoForm.get('phoneNumber')?.hasError('validatePhoneNumber')">
                                Enter a valid phone number
                            </div>
                        </div> -->
                    </div>

                </div>
               

                <div class="col-md-6">
                    <div class="input-wrp">
                        <label for="password" class="label">Password <span class="text-danger">*</span></label>
                        <div class="password-field-wrp">
                            <input type="password" id="password" class="form-control" formControlName="password"
                                placeholder="Password" autocomplete="new-password" (paste)="disableCopyPaste($event)">
                            <button type="button" class="show-hide" tabindex="-1"
                                (click)="togglePasswordVisibility('password')">
                                <img *ngIf="!showPassword" src="./../../../../../assets/img/icon-hide-password.svg"
                                    alt="Hide Password">
                                <img *ngIf="showPassword" src="./../../../../../assets/img/icon-show-password.svg"
                                    alt="Show Password">
                            </button>
                        </div>
                        <div *ngIf="personalInfoForm.get('password')?.invalid && personalInfoForm.get('password')?.touched"
                            class="form-error">
                            <span class="err-msg" *ngIf="personalInfoForm.get('password')?.errors?.['required']">
                                Password is required
                            </span>
                            <!-- <span class="err-msg" *ngIf="personalInfoForm.get('password')?.errors?.['minLength']">
                                Password must be at least 8 characters long.
                            </span> -->

                            <span class="err-msg" *ngIf="personalInfoForm.get('password')?.errors?.['maxlength']">
                                Password cannot exceed 50 characters.
                            </span>
                            
                            <span class="err-msg"
                                *ngIf="!personalInfoForm.get('password')?.errors?.['required'] && personalInfoForm.get('password')?.errors?.['passwordStrength']">
                                Password must be minimum 8 characters with uppercase, lowercase, number, and special
                                character.
                            </span>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="input-wrp">
                        <label for="confirmPassword" class="label">Confirm Password <span
                                class="text-danger">*</span></label>
                        <div class="password-field-wrp">
                            <button type="button" class="show-hide" tabindex="-1"
                                (click)="togglePasswordVisibility('confirmPassword')">
                                <img *ngIf="!showConfirmPassword"
                                    src="./../../../../../assets/img/icon-hide-password.svg" alt="Hide Password">
                                <img *ngIf="showConfirmPassword"
                                    src="./../../../../../assets/img/icon-show-password.svg" alt="Show Password">
                            </button>
                            <input type="password" id="confirmPassword" class="form-control"
                                formControlName="confirmPassword" placeholder="Confirm Password" autocomplete="off"  (paste)="disableCopyPaste($event)">
                        </div>
                        <div *ngIf="personalInfoForm.get('confirmPassword')?.touched && personalInfoForm.get('confirmPassword')?.invalid"
                            class="form-error">
                            <span class="err-msg"
                                *ngIf="personalInfoForm.get('confirmPassword')?.hasError('required')">Confirm Password
                                is required</span>
                            <span class="err-msg"
                                *ngIf="!personalInfoForm.get('confirmPassword')?.hasError('required') && personalInfoForm.get('confirmPassword')?.hasError('passwordMismatch')">
                                Passwords do not match
                            </span>
                        </div>
                    </div>
                </div>

            </div>

            <div class="sep-wrp">
                <h3 class="sep-title">Website Branding</h3>
            </div>

            <div class="input-wrp">
                <label for="logo" class="label">Logo Upload</label>
                <div class="file-upload-wrp">
                    <app-image-dropzone (uploadSuccess)="handleUploadSuccess($event)"
                        (isRemoved)="isAddimgRemove = $event" [imageUrl]="imageData" [signup]="true"
                        (removeImg)="handleRemoveImg($event)" (UploadProgress)="uploadProgress($event)"></app-image-dropzone>

                    <!-- <div *ngIf="personalInfoForm.get('logo')?.touched && !personalInfoForm.get('logo')?.value">
                        <div class="form-error err-msg" style="left: 0px;">
                            Logo is required
                        </div>
                    </div> -->
                </div>

            </div>

            <!-- <div class="row">
                <div class="col-md-6">
                   
                    <div class="input-wrp">
                        <label for="primaryColor" class="label">Primary Colour <span class="text-danger">*</span></label>
                        <div class="colorpick form-control1">
                            <div class="color-options">
                                <label class="color-radio" *ngFor="let color of primaryColors">
                                    <input type="radio" name="primaryColor" [value]="color" [checked]="personalInfoForm.get('primaryColor')?.value === color" (change)="onColorSelect('primaryColor', color)">
                                    <span class="color-preview" [style.backgroundColor]="color"></span>
                                </label>
                                <div *ngIf="personalInfoForm.get('primaryColor')?.touched && personalInfoForm.get('primaryColor')?.invalid"
                                    class="form-error">
                                    <span class="err-msg" *ngIf="personalInfoForm.get('primaryColor')?.hasError('required')">
                                        Primary color is required
                                    </span>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                    
                    
                        
                  
                    
                </div>
                <div class="col-md-6">
                    <div class="input-wrp">
                        <label for="secondaryColor" class="label">Secondary Colour <span class="text-danger">*</span> </label>
                        <div class="colorpick form-control1">
                            <div class="color-options">
                                <label class="color-radio" *ngFor="let color of secondaryColors">
                                    <input type="radio" name="secondaryColor" [value]="color" [checked]="personalInfoForm.get('secondaryColor')?.value === color" (change)="onColorSelect('secondaryColor', color)">
                                    <span class="color-preview" [style.backgroundColor]="color"></span>
                                </label>
                                <div *ngIf="personalInfoForm.get('secondaryColor')?.touched && personalInfoForm.get('secondaryColor')?.invalid"
                                    class="form-error">
                                    <span class="err-msg" *ngIf="personalInfoForm.get('secondaryColor')?.hasError('required')">
                                        Secondary color is required
                                    </span>
                                </div>
                               
                            </div>
                        </div>
                        
                       
                    </div>
                </div>
            </div> -->
            <div class="signup-action-footer">
                <p class="login-txt">Already have an Account? <a routerLink="/signin" class="links links-primary">Log
                        in</a>
                </p>

                <button type="submit" class="btn btn-primary actn-arrows next" [disabled]="loading || imgUpProgress">
                    <span class="txt">Next</span>
                    <span *ngIf="!loading" class="ico">
                        <img src="../../../../assets/img/btn-arrow.svg" alt="next">
                    </span>
                    <span *ngIf="loading" class="spinner-border" role="status" aria-hidden="true"></span>
                </button>
            </div>
        </div>
    </div>
</form>