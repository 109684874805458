import { Component, HostListener, inject, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { StripeElementsOptions, StripePaymentElementOptions } from '@stripe/stripe-js';
import { injectStripe, StripePaymentElementComponent, StripeService } from 'ngx-stripe';
import { PaymentStripeServiceService } from '../../service/payment/payment-stripe-service.service';
import { AuthService } from '../../service/auth-service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { FormFieldService } from '../../../shared/form-field.service';
import { ProfileService } from '../../service/profile-service';
import { catchError, Observable, tap } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { PaymentpopupComponent } from '../../../shared/paymentpopup/paymentpopup.component';
import { StepService } from '../../../shared/step.service';


@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrl: './payment.component.scss'
})

export class PaymentComponent implements OnInit, OnChanges {
  @ViewChild(StripePaymentElementComponent)

  paymentElement!: StripePaymentElementComponent;

  @Input() clientSecret: any;
  @Input() customerId: any;
  @Input() setupIntend: any;
  @Input() BillingDetails: any;
  @Input() priceId: any;
  @Input() Coupon: any;
  loader : boolean =false
  private readonly fb = inject(UntypedFormBuilder);
  private readonly stripeService = inject(PaymentStripeServiceService);
  readonly stripe = injectStripe(this.stripeService.StripePublicKey);

  paymentMethodId: any;

  elementsOptions: StripeElementsOptions = {
    paymentMethodCreation: 'manual',
    locale: 'en',
    appearance: {
      theme: 'flat',
      variables: {
        borderRadius: '50px',
        
      },

    },

  };


  paymentElementOptions: StripePaymentElementOptions = {

    layout: {
      type: 'tabs',
      defaultCollapsed: false,
      radios: false,
      spacedAccordionItems: false
    },
    fields:{
      billingDetails:{
        address:{
          country:'never',
          postalCode:'never'
        }
      }
    },
    
  };


  constructor(
    private stripeServices: StripeService, private router: Router, private route: ActivatedRoute, private spinner: NgxSpinnerService,
    private authService: AuthService, private formfieldService: FormFieldService, private userService: ProfileService,
    private toasterService: ToastrService, private modalService: NgbModal,private stepservice:StepService
  ) { }


  checkoutForm: any;

  ngOnInit() {
    this.loader = true;
    console.log(this.clientSecret)
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['clientSecret'] && changes['clientSecret'].currentValue || changes['BillingDetails'] && changes['BillingDetails'].currentValue ||
      changes['customerId'] && changes['customerId'].currentValue || changes['paymentId'] && changes['paymentId'].currentValue ||
      changes['priceId'] && changes['priceId'].currentValue ||
      changes['Coupon'] && changes['Coupon'].currentValue
    ) {

      this.elementsOptions.clientSecret = this.clientSecret;
      this.BillingDetails = this.BillingDetails;
      this.customerId = this.customerId;
      this.setupIntend = this.setupIntend;
      this.priceId = this.priceId;
      this.Coupon = this.Coupon;
      console.log(this.BillingDetails, this.customerId, this.setupIntend, this.priceId, this.Coupon)

      if(this.clientSecret != null){
        this.loader = false;
      }

    }
  }

  createSubscription(accordian: any): void {
    this.spinner.show()
    console.log(this.BillingDetails.country.country)
    this.paymentElement.elements.submit();
    sessionStorage.setItem('paymentInProgress', 'true');
    this.stripe.createPaymentMethod({
      elements: this.paymentElement.elements,
      params: {
        billing_details: {
          name: this.BillingDetails.firstName + this.BillingDetails.lastName,
          email: this.BillingDetails.email,
          address: {
            line1: this.BillingDetails.address,
            postal_code: this.BillingDetails.postalCode,
            city: this.BillingDetails.city,
            state: this.BillingDetails.state,
            country: this.BillingDetails.country.iso2,
          }
        }
      },
    }).subscribe((result: any) => {
      if (result.error) {
        this.spinner.hide();
        accordian.expand();
        sessionStorage.removeItem('paymentInProgress')
      }
      else if (result) {
        console.log(this.paymentElement, result)
        console.log(result)
        if (result.paymentMethod.type === 'card') {
          this.cardPaymentSubscription(result)
        } else {
          this.pay()
        }

      }

    },
      error => {
        this.spinner.hide()
        console.log(error)
        this.toasterService.error(error, "Error!", {
          progressBar: true,
          // toastClass: "toast ngx-toastr",
          closeButton: true,
          timeOut: 3000,
        });
        sessionStorage.removeItem('paymentInProgress')
      })

  }
  pay() {
    sessionStorage.removeItem('paymentInProgress')
    this.stripe
      .confirmSetup({
        clientSecret: this.clientSecret,
        elements: this.paymentElement.elements,
        confirmParams: {
          payment_method_data: {
            billing_details: {
              name: this.BillingDetails.firstName + this.BillingDetails.lastName,
              email: this.BillingDetails.email,
              address: {
                line1: this.BillingDetails.address,
                postal_code: this.BillingDetails.postalCode,
                city: this.BillingDetails.city,
                state: this.BillingDetails.state,
                country: this.BillingDetails.country.iso2,
              }
            }
          },
          return_url: "https://seoreport.spericorn.com/signup?modal=showSuccessModal"
        },
        redirect: 'if_required',

      })
      .subscribe(result => {

        console.log('Result', result);
        if (result.error) {
          this.spinner.hide();
          sessionStorage.removeItem('paymentInProgress')
          this.popUpModalPayment(false, result, result.error.message)

        } else {
          // The payment has been processed!
          if (result.setupIntent.status === 'succeeded') {
            const payload: {
              customerId: any;
              paymentMethodId: any;
              setUpIntentId: any;
              priceId: any;
              couponId?: any;  // Mark couponId as optional
            } = {
              customerId: this.customerId,
              paymentMethodId: result.setupIntent.payment_method,
              setUpIntentId: this.setupIntend,
              priceId: this.priceId
            };
            if (this.Coupon != null) {
              payload.couponId = this.Coupon;
            }
            console.log(payload)
            this.stripeService.subscribe(payload).subscribe(
              (res: any) => {
                console.log(res)
                if (res.success) {

                  console.log(result)
                  this.spinner.hide();
                  const modalRef = this.popUpModalPayment(true, res)
                  setTimeout(() => {
                    this.loginupdate(res, modalRef);
                  }, 3000);


                }

              }, (err: any) => {
                sessionStorage.removeItem('paymentInProgress')
                this.toasterService.error(err, "Error!", {
                  progressBar: true,
                  // toastClass: "toast ngx-toastr",
                  closeButton: true,
                  timeOut: 3000,
                });
                this.spinner.hide();
              }
            );
            // alert({ success: true });
          }
        }
      }, error => {
        sessionStorage.removeItem('paymentInProgress')
        this.toasterService.error(error, "Error!", {
          progressBar: true,
          // toastClass: "toast ngx-toastr",
          closeButton: true,
          timeOut: 3000,
        });
        this.spinner.hide();
      });

  }
  cardPaymentSubscription(data: any) {
    const payload: {
      customerId: any;
      paymentMethodId: any;
      setUpIntentId: any;
      priceId: any;
      couponId?: any;  // Mark couponId as optional
    } = {
      customerId: this.customerId,
      paymentMethodId: data.paymentMethod.id,
      setUpIntentId: this.setupIntend,
      priceId: this.priceId,
    };
    console.log(this.Coupon)
    if (this.Coupon !== null) {
      console.log(this.Coupon)
      payload.couponId = this.Coupon;
    }

    console.log(payload)
    this.stripeService.subscribe(payload).subscribe(
      (res: any) => {
        console.log(res)
        if (res.success && res.data.subscriptionStatus === 'incomplete') {
          this.stripe.confirmCardPayment(
            res.data.client_secret
          ).subscribe(result => {
            if (result.paymentIntent?.status === 'succeeded') {
              console.log(result)
              this.spinner.hide();
              const modalRef = this.popUpModalPayment(true, res)
              setTimeout(() => {
                this.loginupdate(res, modalRef);
              }, 3000);
            }

            else if (result.error) {
              console.log(result)
              sessionStorage.removeItem('paymentInProgress')
              this.spinner.hide();
              this.popUpModalPayment(false, res, result.error.payment_intent?.last_payment_error?.message ||result.error.code)

            }

          },
            error => {
              this.spinner.hide();
              sessionStorage.removeItem('paymentInProgress')
              this.popUpModalPayment(false, res, error)

            })

        } else if (res.success && res.data.subscriptionStatus === 'active') {
          this.spinner.hide();
          const modalRef = this.popUpModalPayment(true, res)
          setTimeout(() => {
            this.loginupdate(res, modalRef);
          }, 3000);



        }
        else if (res.error) {
          this.spinner.hide();
          sessionStorage.removeItem('paymentInProgress')
          const errorMessage = res.error.message;
          const parts = errorMessage.split(':');
          this.popUpModalPayment(false, res, parts.length > 1 ? parts[1].trim() : errorMessage)
        }

      },
      (err: any) => {
        console.log(err)
        this.spinner.hide();
        sessionStorage.removeItem('paymentInProgress')
        const errorMessage = err;
        const parts = errorMessage.split(':');
        this.popUpModalPayment(false, err, parts.length > 1 ? parts[1].trim() : errorMessage)

      }
    );
  }

  paypalSub(data: any, coupon?: any): Observable<any> {
    this.spinner.show()
    let val = sessionStorage.getItem('setupinit');
    const payload: {
      customerId: any;
      paymentMethodId: any;
      setUpIntentId: any;
      priceId: any;
      couponId?: any;  // Mark couponId as optional
    } = {
      customerId: this.customerId,
      paymentMethodId: data.setupIntent.payment_method,
      setUpIntentId: this.setupIntend,
      priceId: this.priceId
    };
    console.log(this.Coupon)
    if (coupon != null) {
      console.log(coupon)
      payload.couponId = coupon;
    }
    console.log(payload)
    return this.stripeService.subscribe(payload).pipe(
      tap((res: any) => {
        console.log(res);
        if (res.success) {
          // this.loginupdate(res);
        }

      }),
      catchError((err: any) => {
        // Handle errors
        sessionStorage.removeItem('paymentInProgress')
        throw err;
      })
    );
  }
  loginupdate(data: any, modal: any) {
    // this.spinner.show()
    // modal.close();
    const formData = new FormData();
    let form: any = sessionStorage.getItem('personalInfoFormData');
    let paymentAddress :any = sessionStorage.getItem('billingFormData')
    let paymentAddressForm = JSON.parse(paymentAddress)
    let img: any = sessionStorage.getItem('dropImageData');
    let img1: any = JSON.parse(img)
    let dropImage = img1 ? this.base64ToFile(img1.file, img1.name) : null;
    let plans: any = sessionStorage.getItem('plan');
    let plan = JSON.parse(plans)
    // Convert content to a Blob

    let formUpdate = JSON.parse(form)
    formData.append('firstName', formUpdate.firstName);
    formData.append('lastName', formUpdate.lastName);
    formData.append('email', formUpdate.email);

    let phone = formUpdate.phoneNumber;
    let cleanPhoneNumber = phone?.number.replace(/\D/g, ''); // Remove non-digit characters
    if (cleanPhoneNumber.startsWith('0')) {
      cleanPhoneNumber = cleanPhoneNumber.slice(1); // Remove leading zero
    }
    formData.append('phone', cleanPhoneNumber);

    let countryCode = phone?.dialCode;
    formData.append('countryCode', countryCode);

    formData.append('subscriptionId', data.data.subscriptionId);
    formData.append('password', formUpdate.password);
    formData.append('confirmPassword', formUpdate.confirmPassword);

    formData.append('primaryColor', formUpdate.primaryColor);
    formData.append('secondaryColor', formUpdate.secondaryColor);
    formData.append('stripeCustomerId', data?.data?.customerId);
    formData.append('planId', plan?.id);
    formData.append('city',paymentAddressForm.city)
    formData.append('state',paymentAddressForm.state)

    formData.append('country',paymentAddressForm.country.country)

    // formData.append('apartment',paymentAddressForm.apartment)
    formData.append('address',paymentAddressForm.address)

    formData.append('pincode',paymentAddressForm.postalCode)


    // console.log(file,formData)
    if (dropImage) {
      formData.append('logo', dropImage);
    }



    this.authService.addPersonalInfoDetails(formData).subscribe(
      (res: any) => {
        if (res.success) {
          if (modal != 'fg') {
            // modal.close()
          }
          localStorage.clear();
          sessionStorage.clear();
          this.formfieldService.clearAllData();
          this.stepservice.clearAll();
          let token = res?.token?.accessToken

          localStorage.setItem('token', res.token?.accessToken);
          localStorage.setItem('refreshToken', res.token?.refreshToken);
          localStorage.setItem('expiresIn', res.token?.expiresIn);



          if (token) {
            this.getUserDtl(token, modal)
          }
        }
      }, (err: any) => {
        // this.loading = false;
        console.log(err)
        this.toasterService.error(err ,"Error!", {
          progressBar: true,
          // toastClass: "toast ngx-toastr",
          closeButton: true,
          timeOut: 3000,
        });

      }
    );
  }
  getUserDtl(token: any, modal: any) {
    this.authService.getCurrentUserSignup(token).then((res: any) => {
      if (res.success) {
        this.spinner.hide()
        // modal.close()
        this.authService.userProfile = res.user
        // this.profile.userProfile = res.user
        localStorage.setItem('role', res.user.role_id);
        localStorage.setItem('email', res.user.email);
        localStorage.setItem('UserId', res.user.id);
        // console.log( this.authService.userProfile)

        if (res.user.role_id == 1) {
          this.router.navigate(['/admin/dashboard']);
        } else {
          this.router.navigate(['/user/statistics']);
          modal.close()
        }
      }

    }, (err: any) => {
      // this.loading = false;
      this.toasterService.error(err, "Error!", {
        progressBar: true,
        // toastClass: "toast ngx-toastr",
        closeButton: true,
        timeOut: 3000,
      });
    })
  }

  base64ToFile(base64String: string, fileName: string): File {
    // Remove the data URL scheme part if present
    const base64Pattern = /^data:(.*?);base64,/;
    const base64Data = base64String.replace(base64Pattern, '');

    // Convert base64 string to a binary string
    const binaryString = atob(base64Data);

    // Create a byte array
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    // Create a Blob from the byte array
    const blob = new Blob([bytes], { type: 'image/png' });

    // Create a File from the Blob
    const file = new File([blob], fileName, { type: 'image/png' });

    return file;
  }



  popUpModalPayment(paymentStatus: any, subStatus: any, errormsg?: any) {
    const modalRef = this.modalService.open(PaymentpopupComponent, {
      scrollable: true,
      backdrop: 'static',
      windowClass: 'modal-small-width modal-micro fade modal-subscribe-success',
      centered: true
    });
    modalRef.componentInstance.paymentStatus = paymentStatus;
    modalRef.componentInstance.subStatus = subStatus;
    modalRef.componentInstance.errormsg = errormsg;
    return modalRef
  }


  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    if (sessionStorage.getItem('paymentInProgress')) {
      // Show a custom warning message (if supported by the browser)
      $event.returnValue = 'A payment is in progress. Are you sure you want to leave?';
    }
  }


}