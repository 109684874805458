<app-shared-header [showButton2]="true" [showButton1]="false" ></app-shared-header>
<div class="login-wrapper login-page">
    <div class="container">
        <div class="row login-row">
            <div class="col-lg-6 txt-col">
                <div class="title-ico top-ico">
                    <img src="../../../../assets/img/title-top-icons.png" alt="pages">
                </div>
                <div class="sec-title">
                    <span class="ico">
                        <img src="../../../../assets/img/logo-icon-white.svg" alt="ico">
                    </span>
                    <span class="txt">Login your account</span>
                </div>

                <h1 class="title">Real time SEO Montoring & Reporting Platform</h1>

                <p class="subtitle">
                  Creating a website for your company in less than a minute.
                </p>
                <div class="title-ico">
                    <img src="../../../../assets/img/title-bottom-icons.png" alt="pages">
                </div>
            </div>
            <div class="col-lg-6 form-col login-form-col">
                <a href="#" class="login-logo">
                    <img src="../../../../assets/img/logo.svg" alt="logo">
                </a>
                <form action="#" class="login-form login" [formGroup]="userLoginForm" (ngSubmit)="onLogin()">
                    <div class="form-wrp">
                        <div class="form-steps step-0">
                            <div class="input-wrp">
                                <label for="email" class="label">User Name Or E-mail ID <span class="text-danger"> * </span></label>
                                <input id="email" type="text" class="form-control" placeholder="Enter E-mail ID"
                                    formControlName="email">
                                <div *ngIf="f['email'].touched && f['email'].invalid" class="error" [ngClass]="{
                                    'form-error': f['email'].errors && f['email'].touched
                                  }">
                                    <span *ngIf="f['email'].errors?.['required']" class="err-msg">E-mail is required.</span>
                                    <span *ngIf="f['email'].errors?.['email'] || f['email'].errors?.['commonEmail']" class="err-msg">Please enter a valid E-mail address.</span>
                                </div>
                            </div>

                            <div class="input-wrp">
                                <label for="password" class="label">Password <span class="text-danger"> * </span></label>
                                <input id="password" type="password" class="form-control" placeholder="Enter Password"
                                    formControlName="password" (paste)="disableCopyPaste($event)">
                                <div *ngIf="f['password'].touched && f['password'].invalid" class="form-error">
                                    <small *ngIf="f['password'].errors?.['required']" class="err-msg">Password is required.</small>
                                    <small *ngIf="f['password'].errors?.['maxlength']"> Password cannot exceed 50
                                        characters.</small>
                                    <small *ngIf="f['password'].errors?.['pattern']" class="err-msg">Password must contain at least one
                                        number, one uppercase letter, and be at least 6 characters long.</small>
                                </div>
                                <button type="button" class="show-hide" tabindex="-1"
                                (click)="togglePasswordVisibility('password')">
                                <img *ngIf="!showPassword" src="./../../../../../assets/img/icon-hide-password.svg"
                                    alt="Hide Password">
                                <img *ngIf="showPassword" src="./../../../../../assets/img/icon-show-password.svg"
                                    alt="Show Password">
                            </button>
                            </div>

                            <div class="input-wrp">
                                <div class="remember-wrp">
                                    <label for="remember" class="custom-chk">
                                        <input type="checkbox" name="remember" id="remember" class="custom-chk-inp" formControlName="rememberMe">
                                        <span class="chk"></span>
                                        <span class="txt">Remember me</span>
                                    </label>

                                    <a routerLink="/forgot-password" class="links links-primary forgot-link">Forgot Password ?</a>
                                </div>
                            </div>

                            <div class="input-wrp">
                                <button class="btn btn-primary w-100" type="submit" [disabled]="loading">
                                    <span *ngIf="loading" class="spinner-border" role="status" aria-hidden="true"></span>
                                    <span *ngIf="!loading">Login</span>
                                </button>
                            </div>

                            <div class="input-wrp">
                                <p class="signup-txt">Don’t have an account? <a routerLink="/signup"
                                        class="links links-primary">Sign up</a></p>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="bg-wrap"></div>
</div>
